import { AssetsProvider, useQuery, useTranslation } from '@obvio/app'
import { RichText } from '@obvio/template'
import { ascSort } from '@obvio/utils'

import {
  Divider,
  GridWidth,
  Img,
  RelativeStack,
  Title,
} from './RoomsDetailed/components'
import { getImage } from '../utils'
import { ReadMoreLink } from '@/components/ReadMoreLink'
import { QUERIES } from '@/constants/queries'

import type { ReactElement } from 'react'

export type CategoryRooms = {
  title: string
  rooms: { id: string; title: string }[]
}

export function RoomsDetailed(): ReactElement {
  const { t } = useTranslation()

  const { data: categories } = useQuery('getProfitroomRoomCategories', {
    select: QUERIES['rooms-detailed'].getProfitroomRoomCategories,
  })

  const count = categories?.length ?? 0

  return (
    <GridWidth
      gap="medium"
      templateColumns={`repeat(${count}, minmax(0, 1fr))`}
    >
      {categories
        ?.sort((a, b) => ascSort(a.order ?? 0, b.order ?? 0))
        ?.map((category) => (
          <AssetsProvider
            key={category.id}
            path={`/room-category/${category.id}`}
          >
            <RelativeStack kind="vertical" spacing="medium">
              <Img img={getImage(category.image)} />
              <Divider />
              <Title tag="h3" className="LE-text">
                {category.title}
              </Title>
              <div>
                <RichText value={category.description ?? ''} />
              </div>
              <ReadMoreLink noArrow href={`/pokoje/${category.slug}`}>
                {t('label.checkDetails')}
              </ReadMoreLink>
            </RelativeStack>
          </AssetsProvider>
        ))}
    </GridWidth>
  )
}
