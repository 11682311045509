import { notDesktop, styled, Text } from '@obvio/app'
import { Stack, Grid } from '@obvio/ui'

import { MinHeightImage } from '../../utils'

export const Title = styled(Text)`
  font-size: 1.5rem;
`

export const RelativeStack = styled(Stack)`
  position: relative;
`

export const Img = styled(MinHeightImage)`
  height: 28rem;
  min-height: 28rem;
`

export const GridWidth = styled(Grid)`
  max-width: 72rem;
  margin: 0 auto;
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(theme) => theme.stroke};
`
